<template>
  <div class="order">
    <el-row>
      <el-col :span="24">
        <span class="orderAddTitle">品名：</span>
        <el-select class="orderAddSelect" v-model="goods" placeholder="请选择品名">
          <el-option v-for="item in good" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <span class="orderAddTitle">数量：</span>
        <el-input class="orderAddInput" v-model="cangku" placeholder></el-input>
        <span class="orderAddTitle">单价：</span>
        <el-input class="orderAddInput" v-model="price" placeholder></el-input>
        <el-button type="danger" @click="addResource">新增资源单</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <div class="orderTable">
          <el-table ref="multipleTable" :data="qiugou" tooltip-effect="dark" style="width: 100%">
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column label="品名" width="120">
              <template slot-scope="scope">{{ scope.row.name }}</template>
            </el-table-column>
            <el-table-column prop="origin" label="产地" width="120"></el-table-column>
            <el-table-column prop="orderPrice" label="单价" show-overflow-tooltip></el-table-column>
            <el-table-column prop="warehouse" label="仓库" show-overflow-tooltip></el-table-column>
            <el-table-column prop="meterageUnit" label="计量单位" show-overflow-tooltip></el-table-column>
            <el-table-column prop="firmTypeFormat" label="货物状态" show-overflow-tooltip></el-table-column>
            <el-table-column label="修改状态" show-overflow-tooltip>
              <template slot-scope="scope">
                <div @click="cancelOrder(scope.row)">
                  <el-button type="danger">撤单</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-row class="paginationBaojia">
      <el-col :span="24">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="current"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalPage"
        ></el-pagination>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  mounted() {
    this.param_releaseOrder();
  },
  data() {
    return {
      goods: "",
      original: "",
      origin: [],
      current: 1,
      pageSize: 10,
      good: [],
      activeName: "first",
      shell: "批量下架",
      cangku: "",
      qiugou: [],
      price: "",
      tableData: [],
      totalPage: null
    };
  },
  mounted() {
    this.param_selectCommodity();
    this.param_sellerOrderDetail();
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.param_sellerOrderDetail();
    },
    handleCurrentChange(val) {
      this.current = val;
      this.param_sellerOrderDetail();
    },
    addResource() {
      protocolFwd.param_releaseOrder.param.commodityId = this.goods;
      protocolFwd.param_releaseOrder.param.holdId = null;
      protocolFwd.param_releaseOrder.param.price = this.price;
      protocolFwd.param_releaseOrder.param.quantity = this.cangku;
      http.postFront(protocolFwd.param_releaseOrder).then(response => {
        let { code, message, value } = response.data;
        if (code == 0) {
          this.$EL_MESSAGE(message);
          this.param_sellerOrderDetail();
        }
      });
    },
    param_sellerOrderDetail() {
      protocolFwd.param_sellerOrderDetail.param.firmId = this.sessionInfoGetter.firmId;
      protocolFwd.param_sellerOrderDetail.param.page = this.current - 1;
      protocolFwd.param_sellerOrderDetail.param.size = this.pageSize;
      http.postFront(protocolFwd.param_sellerOrderDetail).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          const listDatas = value.content;
          for (let i = 0; i < listDatas.length; i++) {
            if (listDatas[i].firmType == "S") {
              listDatas[i].firmTypeFormat = "卖出";
            } else if (listDatas[i].firmType == "B") {
              listDatas[i].firmTypeFormat = "买入";
            }
          }
          this.qiugou = value.content;
          this.totalPage = value.totalElements;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    handleClick() {
      if (this.activeName == "first") {
        this.shell = "批量下架";
      } else {
        this.shell = "批量上架";
      }
    },
    param_releaseOrder() {
      http.postFront(protocolFwd.param_releaseOrder).then(response => {});
    },
    param_selectCommodity() {
      protocolFwd.param_selectCommodity.param.firmId = null;
      http.postFront(protocolFwd.param_selectCommodity).then(response => {
        const { code, message, value } = response.data;
        if (code == 0) {
          this.good = value;
        } else {
          this.$EL_MESSAGE(message);
        }
      });
    },
    cancelOrder(item) {
      this.$confirm("确认撤单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          protocolFwd.param_undoOrder.param.orderId = item.id;
          http.postFront(protocolFwd.param_undoOrder).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$EL_MESSAGE(message);
              this.param_sellerOrderDetail();
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        })
        .catch(() => {});
    }
  }
};
</script>
<style scoped>
.order {
  padding: 0 20px;
}
.order .orderAddSelect,
.order .orderAddInput {
  margin-right: 10px;
  width: 200px;
}
.order .orderTable {
  margin-top: 20px;
}
.order .paginationBaojia {
  margin-top: 15px;
}
</style>
